<template lang="pug">
TheModal(v-model:show="show" @update:show="$emit('update:show', $event)" :title="modal.title")
	.grid
		template(v-if="modal.step === 0")
			BaseTabs(v-model:active="modal.tab" :map="modal.books")
			TheBook(
				v-if="modal.step === 0"
				:endpoint="BOOKS[modal.tab].endpoint"
				:path="BOOKS[modal.tab].path"
				:left="BOOKS[modal.tab].left"
				:leftSub="BOOKS[modal.tab].leftSub"
				@onClick="selectJob"
				plusHide
				customClick
			)
		template(v-else)
			.link(@click="modal.step = 0") Назад
			TheList(:map="modal.posts" @onClick="selectStatus")
</template>

<script>
import { computed, onMounted, reactive } from 'vue'

import { useApi } from '@/modules/api'

import BOOKS from '../../modules/constants/books'

import TheModal from '../TheModal'
import TheBook from '../TheBook'
import TheList from '../TheList'

import BaseTabs from '../ui/BaseTabs'

export default {
	name: 'ModalJobs',
	components: { BaseTabs, TheList, TheModal, TheBook },
	props: {
		show: {
			type: Boolean,
			required: true
		}
	},
	emits: ['update:show', 'create'],
	setup (props, { emit }) {
		const modal = reactive({
			tab: 'earners',
			books: {
				earners: 'Добытчики',
				ships: 'Плашкоуты',
				transport: 'ПТС',
				factories: 'Заводы'
			},
			posts: {},
			step: 0,
			title: computed(() => modal.step === 0 ? 'Выберите организацию' : 'Выберите должность'),
			data: {}
		})

		onMounted(async () => {
			const { get, data } = useApi('/worker/workerpost/all')

			await get()

			data.value.forEach(({ id, name: left }) => {
				modal.posts[id] = { left }
			})
		})

		const selectJob = ({ id: orgId, value }) => {
			modal.data = { orgId, title: value.left, type: BOOKS[modal.tab].type }
			modal.step = 1
		}

		const selectStatus = (postId) => {
			emit('create', { ...modal.data, postId, status: modal.posts[postId].left })
			close()
		}

		const close = () => {
			emit('update:show', false)

			modal.data = {}
			modal.step = 0
		}

		return {
			BOOKS,
			modal,
			selectJob,
			selectStatus
		}
	}
}
</script>
